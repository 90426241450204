@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

:root {
  --slide-percentage: calc(100% / var(--total-slides, 4));
  --themeBold: #7E22CE ;
  --themeLight: #9333EA;
}

body {
  margin: 0;
  overflow-x: hidden;
  font-family: "poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.react-multi-carousel-list {
  position: unset !important;
}
.popular_categories .react-multi-carousel-track li {
  min-width: 160px;
}
.related_prods .react-multi-carousel-track li {
  min-width: 155px;
}
.custom_slider_item .slick-dots{
  bottom: 0;
}
@media (max-width: 991px) {
  .react-multi-carousel-track {
    gap: .5rem;
  }
}
