@media (max-width: 768px) {
    .justifyLocal {
        display: flex;
        justify-content: center;
    }
    .pleftright {
        margin-left: 15%;
        margin-right: 5%;
        margin-bottom: 20px;
    }
}