.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@media (max-width: 767px) {
  .scrollable {
    overflow-x: scroll;
    width: 100%;
    white-space: nowrap;
  }
 
}
@media (max-width: 767px) {
  .scrollpart {
    display: flex;
    gap: 4px;
    justify-content: flex-start;
    width: max-content;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */

}
